var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.store.searchResults)?_c('div',[(_vm.numResults)?_c('SimplePagination',{attrs:{"page":_vm.page,"num-per-page":_vm.numPerPage,"total":_vm.numResults},on:{"page":_vm.goToPage,"numperpage":_vm.onNumPerPageChange}}):_vm._e(),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.store.searchResults},on:{"headingClick":_vm.onHeadingClick},scopedSlots:_vm._u([{key:"songTitle",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":("" + _vm.songPath + (row.ccliSongId))}},[_c('strong',[_vm._v(_vm._s(row.songTitle))])])]}},{key:"contributors",fn:function(ref){
var row = ref.row;
return _vm._l((row.contributors),function(c,i){return _c('div',{key:((row.index) + "cont" + i)},[_c('router-link',{attrs:{"to":("/intellectualproperty/songs?s=" + (encodeURIComponent(c)))}},[_vm._v(" "+_vm._s(c)+" ")])],1)})}},{key:"catalogs",fn:function(ref){
var row = ref.row;
return _vm._l((row.catalogs),function(c,i){return _c('div',{key:((row.index) + "c" + i)},[_vm._v(" "+_vm._s(c.name)+" ("+_vm._s(c.percent)+"%) ")])})}},{key:"lyrics",fn:function(ref){
var row = ref.row;
return [(row.hasLyrics)?_c('a',{attrs:{"href":"#","type":"button","title":_vm.$t('R.LIT_HasLyrics')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openLyricsModal(row.ccliSongId, row.songTitle)}}},[_c('SvgIcon',{attrs:{"icon":"lyrics"}})],1):_c('div')]}},{key:"chords",fn:function(ref){
var row = ref.row;
return [(row.hasChords)?_c('a',{attrs:{"href":"#","type":"button","title":_vm.$t('R.LIT_HasChords')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.openChordsModal(row.ccliSongId, row.songTitle, row.contributors.join(', '))}}},[_c('SvgIcon',{attrs:{"icon":"chord"}})],1):_vm._e()]}},{key:"mobile",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('router-link',{attrs:{"to":("" + _vm.songPath + (row.ccliSongId))}},[_c('strong',[_vm._v(_vm._s(row.songTitle))])]),(row.hasLyrics)?_c('a',{staticClass:"ms-2",attrs:{"href":"#","type":"button","title":_vm.$t('R.LIT_HasLyrics')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openLyricsModal(row.ccliSongId, row.songTitle)}}},[_c('SvgIcon',{attrs:{"icon":"lyrics"}})],1):_vm._e(),(row.hasChords && _vm.featureToggleChords)?_c('a',{staticClass:"ms-2",attrs:{"href":"#","type":"button","title":_vm.$t('R.LIT_HasChords')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openChordsModal(row.ccliSongId, row.songTitle)}}},[_c('SvgIcon',{attrs:{"icon":"chord"}})],1):_vm._e()],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t('R.LIT_SongId'))+": "+_vm._s(row.ccliSongId)+" ")]),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t('R.LIT_SongContributors'))+": "),_vm._l((row.contributors),function(c,i){return _c('span',{key:((row.index) + "cont" + i)},[_c('router-link',{attrs:{"to":("/intellectualproperty/songs?s=" + (encodeURIComponent(c)))}},[_vm._v(" "+_vm._s(c)+" ")]),(i !== row.contributors.length-1)?[_vm._v(",")]:_vm._e()],2)})],2),_c('div',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.$t('R.LIT_Catalogs'))+": "),_vm._l((row.catalogs),function(c,i){return _c('span',{key:((row.index) + "c" + i)},[_c('span',[_vm._v(" "+_vm._s(c.name)+" ("+_vm._s(c.percent)+"%)"),(i !== row.catalogs.length-1)?[_vm._v(",")]:_vm._e()],2)])})],2)]}}],null,false,3173092656)}),(_vm.numResults)?_c('SimplePagination',{attrs:{"page":_vm.page,"num-per-page":_vm.numPerPage,"total":_vm.numResults},on:{"page":_vm.goToPage,"numperpage":_vm.onNumPerPageChange}}):_vm._e(),_c('LyricsModal',{ref:"lyricsModal"}),_c('ChordsModal',{ref:"chordsModal"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }